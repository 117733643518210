import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Divider } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import tiposCampo from '../data/constants/tiposCampo';
import tiposContrato from '../data/constants/tiposContrato';
import cargos from '../data/constants/cargos';
import estados from '../data/constants/estados';
import regionales from '../data/constants/regionales';

function DialogoEditar(props) {
    const { theme, personal, getAccessTokenWithRefresh, setMessage, setSeverity, setSnack, setShowBackdrop, API_DEFAULT, obtenerPersonal } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;
    const [regional, setRegional] = useState(null);
    const [tipoCampo, setTipoCampo] = useState(null);
    const [tipoContrato, setTipoContrato] = useState(null);
    const [cargo, setCargo] = useState(null);
    const [estado, setEstado] = useState(null);
    const [fechaInicioContrato, setFechaInicioContrato] = useState(null);

    useEffect(() => {
        setRegional(personal.regional);
        setTipoCampo(personal.tipo_campo);
        setTipoContrato(personal.tipo_contrato);
        setCargo(personal.cargo);
        setEstado(personal.estado);
        setFechaInicioContrato(personal.fecha_inicio_contrato);
    }, [personal]);

    const limpiar = () => {
        setRegional(personal.regional);
        setTipoCampo(personal.tipo_campo);
        setTipoContrato(personal.tipo_contrato);
        setCargo(personal.cargo);
        setEstado(personal.estado);
        setFechaInicioContrato(personal.fecha_inicio_contrato);
        setOpen(false);
    }

    const putInformacion = async (access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/personal/crear_personal/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'persona': personal.cedula,
                'tipo_campo': tiposCampo.filter((x) => x.tipo === tipoCampo)[0].id,
                'regional': regionales.filter((x) => x.regional === regional)[0].id,
                'tipo_contrato': tiposContrato.filter((x) => x.tipo === tipoContrato)[0].id,
                'cargo': cargos.filter((x) => x.cargo === cargo)[0].id,
                'estado': estados.filter((x) => x.estado === estado)[0].id,
                'fecha_inicio_contrato': createDate(fechaInicioContrato)
            })
        });

        if (res.ok) {
            obtenerPersonal(access);
            setMessage('Se ha actualizado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            setOpen(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        putInformacion(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    function createDate(date) {
        if (date === null || date === '') {
            return null;
        } else {
            let fecha = new Date(date);
            if (fecha.toString() === 'Invalid Date') {
                return null;
            } else {
                return fecha.toISOString().split('T')[0];
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Está editando a {personal.cedula}: {personal.nombre}</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant='body1'>Regional:</Typography>
                <Autocomplete
                    value={regional}
                    onChange={(e, v) => setRegional(v)}
                    options={
                        regionales.map(option => option.regional)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Tipo de campo:</Typography>
                <Autocomplete
                    value={tipoCampo}
                    onChange={(e, v) => setTipoCampo(v)}
                    options={
                        tiposCampo.map(option => option.tipo)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Tipo de contrato:</Typography>
                <Autocomplete
                    value={tipoContrato}
                    onChange={(e, v) => { setTipoContrato(v); if (v === 'Prestación de servicios' || v === 'No asignado') { setCargo('No aplica') } else { if (personal.cargo === 'No aplica') { setCargo('Auxiliar operativo') } else { setCargo(personal.cargo) } } }}
                    options={
                        tiposContrato.map(option => option.tipo)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Cargo:</Typography>
                <Autocomplete
                    value={cargo}
                    onChange={(e, v) => setCargo(v)}
                    options={
                        (tipoContrato === 'Prestación de servicios' || tipoContrato === 'No asignado' ?
                            ['No aplica']
                            :
                            cargos.filter((x) => x.cargo !== 'No aplica').map(option => option.cargo)
                        )
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Estado:</Typography>
                <Autocomplete
                    value={estado}
                    onChange={(e, v) => setEstado(v)}
                    options={
                        estados.map(option => option.estado)
                    }
                    noOptionsText='No hay coincidencias'
                    disableClearable={true}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
                <Typography variant='body1' style={{ marginTop: theme.spacing(1) }}>Fecha de inicio de contrato:</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                    <DatePicker
                        value={parseISO(fechaInicioContrato)}
                        onChange={(date) => setFechaInicioContrato(date)}
                        size='small'
                        inputVariant='outlined'
                        format="EEEE, dd 'de' MMM 'del' yyyy"
                        fullWidth={true}
                        disableToolbar={true}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => putInformacion()}>
                    Aceptar
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => limpiar()} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoEditar;