import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    Container,
    IconButton,
    Button,
    Tooltip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import NumberFormat from 'react-number-format';
import { saveAs } from 'file-saver';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import DialogoEditar from './DialogoEditar';
import regionales from '../data/constants/regionales';
import tiposCampo from '../data/constants/tiposCampo';
import tiposContrato from '../data/constants/tiposContrato';
import estados from '../data/constants/estados';
import cargos from '../data/constants/cargos';

const useStyles = makeStyles({
    table: {
        minWidth: 1200,
    },
    menuItem: {
        fontSize: 14
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#2E7D32'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});

themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function Tabla(props) {
    const { theme, API_DEFAULT, getAccessTokenWithRefresh, validRole, setShowBackdrop, setMessage, setSeverity, setSnack } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const [personalEditar, setPersonalEditar] = useState({});
    const [openEditar, setOpenEditar] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [datos, setDatos] = useState([]);
    const [count, setCount] = useState(0);
    const [filtering, setFiltering] = useState(false);
    const [filtros, setFiltros] = useState(Array(9).fill(''));

    const clearDisabled = !filtros.some((v, i) => {
        if (i < 4 && v !== '') return true;
        else if (i === 4 && (v !== 'Todas' && v !== '')) return true;
        else if (i > 4 && (v !== 'Todos' && v !== '')) return true;
        return false;
    });

    const obtenerPersonal = async (access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/personal/gestor_personal/inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&cedula=${filtros[0] === '' ? 'all-elements' : filtros[0]}&nombre=${filtros[1] === '' ? 'all-elements' : filtros[1]}&celular=${filtros[2] === '' ? 'all-elements' : filtros[2]}&correo=${filtros[3] === '' ? 'all-elements' : filtros[3]}&regional=${filtros[4] === 'Todas' || filtros[4] === '' ? 'all-elements' : filtros[4]}&campo=${filtros[5] === 'Todos' || filtros[5] === '' ? 'all-elements' : filtros[5]}&contrato=${filtros[6] === 'Todos' || filtros[6] === '' ? 'all-elements' : filtros[6]}&cargo=${filtros[7] === 'Todos' || filtros[7] === '' ? 'all-elements' : filtros[7]}&estado=${filtros[8] === 'Todos' || filtros[8] === '' ? 'all-elements' : filtros[8]}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    obtenerPersonal(newAccess);
                }
            }
            else {
                setDatos(res[0]);
                setCount(res[1]);
                setShowBackdrop(false);
            }
        });
    }

    const descargarExcel = async (access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/personal/excel_gestor_personal/`, {
            headers: { 'Authorization': 'Bearer ' + access },
        })

        if (res.ok) {
            const date = new Date().toISOString().split('T')[0]
            const filename = `Gestor de personal ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        descargarExcel(newAccess);
                    }
                }
            });
        }
    }

    const clearFilters = () => {
        for (let i = 0; i < 4; i++) {
            filtros[i] = '';
        }
        filtros[4] = 'Todas';
        for (let i = 5; i < 9; i++) {
            filtros[i] = 'Todos';
        }
        setFiltros([...filtros]);
    }

    useEffect(() => { filtros[4] = 'Todas'; for (let i = 5; i < 9; i++) { filtros[i] = 'Todos' } setFiltros([...filtros]) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { obtenerPersonal() } }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { obtenerPersonal() } }, [page, filtering, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='Table-body'>
            <Container component='main' maxWidth='xl'>
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                        classes={{
                            title: classes.cardTitle
                        }}
                        title={
                            <React.Fragment>
                                <Typography variant='h6'>Tabla de Personal</Typography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title="Descargar Excel">
                                        <IconButton size='small' style={{ marginRight: theme.spacing(2) }} onClick={() => descargarExcel()}>
                                            <ListAltIcon color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                    <Button size='small' variant='contained' color='primary' href='https://registro.desarrolloscnc.com'>Crear personal</Button>
                                </div>
                            </React.Fragment>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <TableContainer component={Paper} variant='outlined' style={{ borderBottom: '0px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'></TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Cédula</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Nombre</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Celular</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Correo electrónico</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Regional</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Tipo de campo</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Tipo de contrato</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Cargo</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Estado</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} component='th' scope='row' align='center'>
                                            <div style={{ display: 'flex' }}>
                                                <ThemeProvider theme={themeDownloadButton}>
                                                    <IconButton
                                                        style={{ marginRight: 4 }}
                                                        size='small'
                                                        color='primary'
                                                        disabled={clearDisabled || filtering}
                                                        onClick={() => {
                                                            setPage(0);
                                                            setFiltering(true);
                                                        }}
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </ThemeProvider>
                                                <IconButton
                                                    size='small'
                                                    color='secondary'
                                                    onClick={() => {
                                                        clearFilters();
                                                        if (filtering) { setPage(0); setFiltering(false); }
                                                    }}
                                                    disabled={clearDisabled}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[0]}
                                                onValueChange={(e) => { filtros[0] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[1]}
                                                onChange={(e) => { filtros[1] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[2]}
                                                onValueChange={(e) => { filtros[2] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[3]}
                                                onChange={(e) => { filtros[3] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[4]}
                                                onChange={(e, v) => { filtros[4] = v; setFiltros([...filtros]) }}
                                                options={
                                                    [{ id: 'Todas', regional: 'Todas' }].concat(regionales).map(option => option.regional)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todas')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[5]}
                                                onChange={(e, v) => { filtros[5] = v; setFiltros([...filtros]) }}
                                                options={
                                                    [{ id: 'Todos', tipo: 'Todos' }].concat(tiposCampo).map(option => option.tipo)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[6]}
                                                onChange={(e, v) => { filtros[6] = v; setFiltros([...filtros]) }}
                                                options={
                                                    [{ id: 'Todos', tipo: 'Todos' }].concat(tiposContrato).map(option => option.tipo)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[7]}
                                                onChange={(e, v) => { filtros[7] = v; setFiltros([...filtros]) }}
                                                options={
                                                    (filtros[6] === 'Prestación de servicios' || filtros[6] === 'No asignado' ?
                                                        [{ id: 'Todos', cargo: 'Todos' }, { id: 'No aplica', cargo: 'No aplica' }]
                                                        :
                                                        [{ id: 'Todos', cargo: 'Todos' }].concat(cargos)).map(option => option.cargo)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[8]}
                                                onChange={(e, v) => { filtros[8] = v; setFiltros([...filtros]) }}
                                                options={
                                                    [{ id: 'Todos', estado: 'Todos' }].concat(estados).map(option => option.estado)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {datos.map((row) => (
                                        <TableRow key={row.cedula}>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} component='th' scope='row' align='center'>
                                                <Typography style={{ color: '#1565C0', fontWeight: 500, fontSize: 14, cursor: 'pointer', paddingRight: theme.spacing(1.2) }} onClick={() => { if (!filtering) { clearFilters() } setPersonalEditar(row); setOpenEditar(true) }}>Editar</Typography>
                                            </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'> {row.cedula} </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 200 }} align='left'>{row.nombre}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'>{row.celular}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 150, wordBreak: 'break-word' }} align='left'>{row.correo}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.regional}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.tipo_campo}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.tipo_contrato}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.cargo}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.estado}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component={Paper}
                            variant='outlined'
                            style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(e, n) => { if (!filtering) { clearFilters() } setPage(n) }}
                            onChangeRowsPerPage={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(0) }}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                            ActionsComponent={TablePaginationActions}
                        />

                    </CardContent>
                </Card>
            </Container>
            <DialogoEditar
                theme={theme}
                personal={personalEditar}
                open={[openEditar, setOpenEditar]}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                setShowBackdrop={setShowBackdrop}
                obtenerPersonal={obtenerPersonal}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
        </div>
    );
}

export default Tabla;