const cargos = [    
    {'id': 'AC', 'cargo': 'Asistente de Codificación'},
    {'id': 'AIC', 'cargo': 'Asistente de investigación cualitativa'},
    {'id': 'ALP', 'cargo': 'Asistente Logístico de Producción'},
    {'id': 'ANS', 'cargo': 'Analista de seleccion de personal de campo'},
    {'id': 'AO', 'cargo': 'Auxiliar operativo'},
    {'id': 'AP', 'cargo': 'Asistente de producción'},
    {'id': 'APC', 'cargo': 'Asistente de Producción Campo Cualitativo'},
    {'id': 'APP', 'cargo': 'Asistente de Producción Campo Presencial'},
    {'id': 'APPC', 'cargo': 'Asistente de Productividad de Personal y Contratistas'},
    {'id': 'APS', 'cargo': 'Aprendiz Sena'},
    {'id': 'APT', 'cargo': 'Asistente de Producción Campo Teléfonico'},
    {'id': 'APY', 'cargo': 'Asistente de Proyectos'},
    {'id': 'ARH', 'cargo': 'Asistente de Recursos Humanos'},
    {'id': 'AS', 'cargo': 'Asistente de selección'},
    {'id': 'ASL', 'cargo': 'Analista de selección'},
    {'id': 'AUP', 'cargo': 'Auditor presencial'},
    {'id': 'AV', 'cargo': 'Asistente de Vicepresidencia'},
    {'id': 'AXL', 'cargo': 'Auxiliar Logístico'},
    {'id': 'AXP', 'cargo': 'Auxiliar de producción'},
    {'id': 'C', 'cargo': 'Codificador'},
    {'id': 'CAC', 'cargo': 'Coordinador Asistente de selección de Campo'},
    {'id': 'CAD', 'cargo': 'Coordinador Administrativo'},
    {'id': 'CAR', 'cargo': 'Coordinador Administrativo Regional'},
    {'id': 'CAU', 'cargo': 'Coordinador Administrativo Campo Cualitativo'},
    {'id': 'CC', 'cargo': 'Coordinador de campo'},
    {'id': 'CCC', 'cargo': 'Coordinador de Critica y Codificación'},
    {'id': 'CCP', 'cargo': 'Coordinador de campo presencial'},
    {'id': 'CCT', 'cargo': 'Coordinador de campo telefónico'},
    {'id': 'CCU', 'cargo': 'Coordinador de campo cualitativo'},
    {'id': 'CCV', 'cargo': 'Coordinadora de Campo Virtual'},
    {'id': 'CL', 'cargo': 'Coordinador de Logística'},
    {'id': 'CPS', 'cargo': 'Coordinador Profesional de Selección de Campo'},
    {'id': 'CPT', 'cargo': 'Coordinador Profesional de Campo Telefonico'},
    {'id': 'DCC', 'cargo': 'Director de Campo Cualitativo'},
    {'id': 'DCP', 'cargo': 'Director de Campo Presencial'},
    {'id': 'DCT', 'cargo': 'Director de Campo Telefonico'},
    {'id': 'GCT', 'cargo': 'Gestor de Contratos y Talento'},
    {'id': 'GFV', 'cargo': 'Gestor de formación y validación'},
    {'id': 'GP', 'cargo': 'Gestor De Producción'},
    {'id': 'GS', 'cargo': 'Gestor Administrativo de Selección'},
    {'id': 'GSC', 'cargo': 'Gestor Administrativo de Selección de Campo'},
    {'id': 'GSV', 'cargo': 'Gestor de seguimiento y verificación'},
    {'id': 'NA', 'cargo': 'No aplica'},
    {'id': 'PD', 'cargo': 'Promotor de datos'},
    {'id': 'PP', 'cargo': 'Profesional de Proyectos'},
    {'id': 'R5', 'cargo': 'Realizador 5411'},
    {'id': 'R8', 'cargo': 'Realizador 8373'},
    {'id': 'RD', 'cargo': 'Revisor Digital'},
    {'id': 'RIP', 'cargo': 'Recolector de información presencial'},
    {'id': 'RIT', 'cargo': 'Recolector de información telefónico'},
    {'id': 'SC', 'cargo': 'Supervisor de Citación'},
    {'id': 'SCP', 'cargo': 'Supervisor de Campo Presencial'},
    {'id': 'SCT', 'cargo': 'Supervisor de Campo Telefonico'},
    {'id': 'SDCP', 'cargo': 'Subdirectora de Campo Presencial'},
    {'id': 'SRD', 'cargo': 'Supervisor de Revisión Digital'},
    {'id': 'SUP', 'cargo': 'Supervisor presencial'},
    {'id': 'SUT', 'cargo': 'Supervisor telefónico'},
    {'id': 'VP', 'cargo': 'Vicepresidente de Producción'}
]

export default cargos;