const tiposContrato =[
    {id: 0, tipo: 'No asignado'},
    {id: 1, tipo: 'Prestación de servicios'},
    {id: 2, tipo: 'Obra o labor'},
    {id: 3, tipo: 'Término fijo'},
    {id: 4, tipo: 'Término indefinido'},
    {id: 5, tipo: 'No aplica'}
];

export default tiposContrato;
